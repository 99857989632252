import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {TextControllerRHF} from "../../../../../generic/fields";
import {useForm} from "react-hook-form";
import {useCertificatesApi} from "../../../../../../lib/api/certificates";
import PriceCurrency from "../../../../../common/reactHookFormsFields/PriceCurrency";
import {t} from "i18next";
import CurrencyControllerRHF from "../../../../../generic/fields/CurrencyControllerRHF";

const CertificateChangeModal = ({triggerModal, isOpened, state, dispatch}) => {
    const setCertificate = data => dispatch({type: 'setForm', payload: data});
    const {changeCertificate} = useCertificatesApi();
    const {handleSubmit, control, errors} = useForm({reValidateMode: 'onBlur',});
    const [size, setSize] = useState(state.size || 0);
    const [leftSize, setLeftSize] = useState(state.size_left || 0);
    const [priceCurrency, setPriceCurrency] = useState(state.price_currency || 'RUB');

    const onSubmit = handleSubmit(() =>
        changeCertificate(size, priceCurrency, leftSize, state.id)
            .then(res => setCertificate({size, price_currency: priceCurrency, size_left: leftSize, is_active: res.is_active}))
            .then(triggerModal))
    return <>
        <Modal isOpen={isOpened} toggle={triggerModal} size={'md'} unmountOnClose>
            <ModalHeader>{t('management.certificates.changeModal.header')}</ModalHeader>
            <ModalBody>
                <Form>
                    <Row className={'justify-content-around'}>
                        <Col xs={12} md={5}>
                            <FormGroup>
                                <CurrencyControllerRHF inputName={'size'} control={control} errors={errors}
                                                   defaultValue={size} isRequired
                                                   label={t('management.certificates.changeModal.sizeInputLabel')}
                                                   disabled={state.order.payments?.[0]?.invoice_link}
                                                   setValue={e => setSize(() => e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={5}>
                            <FormGroup>
                                <PriceCurrency form={{control, errors}} setState={e => setPriceCurrency(e.target.value)}
                                               state={{price_currency: priceCurrency}} isRequired
                                               disabled={state.order.payments?.[0]?.invoice_link}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={11}>
                            <FormGroup>
                                <CurrencyControllerRHF inputName={'size_left'} control={control} errors={errors}
                                                   defaultValue={leftSize} isRequired
                                                   setValue={e => setLeftSize(() => e.target.value)}
                                                   label={t('management.certificates.changeModal.sizeLeftInputLabel')}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Row>
                    <Col>
                        <Button color="primary" onClick={onSubmit}>
                            {t('management.certificates.changeModal.ok')}
                        </Button>
                    </Col>
                    <Col>
                        <Button color="secondary" outline onClick={triggerModal}>
                            {t('management.certificates.changeModal.cancel')}
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>

    </>
}

CertificateChangeModal.propTypes = {
    state: PropTypes.object.isRequired,
    triggerModal: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired

}

export default CertificateChangeModal;


