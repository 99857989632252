import React, {useEffect, useState} from 'react';
import {Col, FormGroup, FormText, Input, Label, Row} from "reactstrap";
import {Switch} from "../../../../generic/buttons";
import PropTypes from 'prop-types';
import {useBoatsClassesApi} from "../../../../../lib/api/boatsClasses";
import {t} from "i18next";
import ElementWithAccessInCountries from '../../../../../lib/utils/hocs/ElementWithAccessInCountries';
import {Controller, useForm} from "react-hook-form";
import FieldWithValidation from "../../../../generic/fields/FieldWithValidation";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";

const Discount = ({state, dispatch, label = '', discount_name = '', disabled}) => {
    const discountSize = state[discount_name + '_discount_size'];
    const [isDiscountActive, setDiscountActive] = useState(!!discountSize || false);

    const clearDiscountData = () => dispatch({type: 'clearDiscountData', payload: discount_name})
    const {control, errors} = useForm({reValidateMode: 'onBlur',});
    const [alternativePublicContracts, setAlternativePublicContracts] = useState([]);
    const {getEntries} = useDefaultEndpoints('public-contracts');

    useEffect(() => {
        if (disabled) {
            clearDiscountData()
            setDiscountActive(() => false)
        }
    }, [disabled])

    useEffect(() => {
        setDiscountActive(!!discountSize);
    }, [discountSize]);

    useEffect(() => {
        getEntries({}).then(res => setAlternativePublicContracts(() => res))
    }, [])

    return <Row>
        <Col xs={12} md={8}>
            <Label>{label}</Label>
        </Col>
        <Col xs={12} md={4}>
            <Switch disabled={disabled} value={isDiscountActive}
                    onChange={() => {
                        setDiscountActive(isActive => {
                            if (isActive) clearDiscountData()
                            return !isActive
                        })
                    }}/>
        </Col>
        {isDiscountActive && <>
            <Col xs={6} md={2}>
                <Label>{t('bases.base.type')}</Label>
                <Input bsSize={'sm'} type={'select'} name={discount_name + '_discount_type'}
                       value={state[discount_name + '_discount_type']}
                       onChange={e => dispatch({type: 'setFormData', payload: e})}
                >
                    <option value={'summ'}>{t('bases.base.sum')}</option>
                    <option value={'percent'}>{t('bases.base.percent')}</option>
                </Input>
            </Col>
            <Col xs={6} md={3}>
                <Label>{t('bases.base.sum')}</Label>
                <Input bsSize={'sm'} type={'text'} name={discount_name + '_discount_size'}
                       value={state[discount_name + '_discount_size']}
                       onChange={e => dispatch({type: 'setFormData', payload: e})}
                />
            </Col>
            <Col xs={6} md={6}>
                <Label>{t('bases.base.discountText')}</Label>
                <Input type={'text'} name={discount_name + '_discount_info'}
                       value={state[discount_name + '_discount_info']}
                       onChange={e => dispatch({type: 'setFormData', payload: e})}
                       placeholder={t('bases.base.discountTextPlaceholder')}
                />
            </Col>
            {discount_name === 'own_boat'
                && <Col xs={12} md={11}>
                    <FormGroup>
                        <Controller
                            control={control}
                            name={discount_name + '_public_contract_url'}
                            defaultValue={state[discount_name + '_public_contract_url']}
                            render={(
                                {value, onBlur, name, ref, onChange},
                                {invalid}
                            ) =>
                                <FieldWithValidation invalid={invalid} errors={errors} label={t('bases.base.alternativePublicContract')}
                                                     name={name}
                                >
                                    <Input type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                                           style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                           value={value}
                                           onChange={e => {
                                               dispatch({type: 'setFormData', payload: e});
                                               onChange(e.target.value)
                                           }}>
                                        <option value={''} disabled>{t('bases.base.pickContract')}</option>
                                        {alternativePublicContracts.map(alternativePublicContract => <option value={alternativePublicContract.link}
                                                                                       key={alternativePublicContract.id}>{alternativePublicContract.tag}</option>)}
                                    </Input>
                                </FieldWithValidation>
                            }
                        />
                    </FormGroup>
                </Col>}
        </>}
    </Row>
}

const Discounts = ({state, dispatch}) => {
    const {getBoatsPlaces} = useBoatsClassesApi();
    const [isFullBookHintVisible, setFullBookHintVisible] = useState(false);

    useEffect(() => {
        if (state.boat_class || state.event_id?.boat_class)
            getBoatsPlaces(state.boat_class || state.event_id?.boat_class)
                .then(res => setFullBookHintVisible(() => +res.places === 1))
    }, [state.boat_class, state.event_id?.boat_class])

    return <>
        <Row className={'mb-3'}>
            <Col>
                <h5 className={'display-4'}>{t('bases.base.discounts')}</h5>
            </Col>
        </Row>

      <ElementWithAccessInCountries hideInCountries={['EN']}>
        <Discount dispatch={dispatch} label={t('bases.base.coachlessDisc')}
                  discount_name={'coachless'} state={state}
        />
      </ElementWithAccessInCountries>
        {(state?.boat_class === 'Луч' || state.event_id?.boat_class === 'Луч')
            &&
            <ElementWithAccessInCountries hideInCountries={['EN']}>
                <Discount dispatch={dispatch} label={t('bases.base.ownBoat')}
                             discount_name={'own_boat'} state={state} disabled={!state.is_own_boat}
                />
            </ElementWithAccessInCountries>}
        <Discount dispatch={dispatch} label={t('bases.base.fullbookDisc')}
                  discount_name={'full_book'} state={state} disabled={!state.is_full_book_available || isFullBookHintVisible}
        />

        {isFullBookHintVisible &&
        <FormGroup>
            <FormText>{t('bases.base.singleBoat')}</FormText>
        </FormGroup>
        }

        {!state.is_full_book_available &&
        <FormGroup>
            <FormText>{t('bases.base.fullbookUnavailable')}</FormText>
        </FormGroup>
        }
    </>
};

export default Discounts;

Discounts.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
}