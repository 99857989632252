import React, {useEffect, useState} from 'react';
import {TableCard} from '../../../generic/cards';
import {Button, Col, CustomInput, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {handleInputChange} from '../../../../lib/utils/handleInputChange';
import {useHistory, useParams} from 'react-router-dom';
import {datetime} from "../../../../lib/utils/format";
import OrderTable from "./OrderTable";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import ViewModal from "./ViewModal";
import {toast} from "react-toastify";
import {useOrdersApi} from "../../../../lib/api/orders";
import CustomFormControls from "./CustomFormControls";
import {TiTicket} from "react-icons/ti";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {AiOutlineBook, AiOutlineWallet} from "react-icons/ai";
import {withBreadcrumbs} from "../../../common/hoc/Breadcrumbs";
import {ElementWithPermissions} from "../../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import CurrencyInput from "../../../generic/fields/CurrencyInput";

const Order = withBreadcrumbs(({
                   product_type = 'slots', product_url, isInline, onClickCb,
                   onAnnulationCb, priceCurrency, orderId, productId, presetFilters,
}) => {
    let history = useHistory();
    const {id} = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [isPaidForOpen, setIsPaidForOpen] = useState(false);
    const [state, setState] = useState({
        client_id: {},
        paid_for_ids: [],
        product_type: product_type,
        payment_currency: priceCurrency || 'RUB',
        product_url: product_url
    });
    const permissions = useRoleSystem(ENTITIES.ORDERS);

    const getCurrencies = useDefaultEndpoints('currencies').getEntries;
    const [currencies, setCurrencies] = useState([])

    const setOrderData = (e) => {
        const {name, value} = handleInputChange(e)
        setState((state) => ({...state, [name]: value}))
    };

    const setCurrencyData = target => {
        const {name, value} = target
        setState((state) => ({...state, [name]: value}))
    };

    const {getEntry, createEntry, editEntry, setPaid, setPartialPayInFull} = useOrdersApi('orders');

    const changePaymentStatus = async () => setPaid(state.id, !state.has_paid)
        .then(res => {
            toast.success(t('management.orders.order.paymentStatusChanged'));
            onAnnulationCb && onAnnulationCb(res)
            history.go(0)
        }).catch((e) => toast.error(e))

    const changePartialPayInFull = () => setPartialPayInFull(state.id)
        .then(() => {
            toast.success(t('management.orders.order.paid'));
            history.go(0)
        }).catch((e) => toast.error(e))

    const toggle = () =>  setIsOpen(!isOpen);

    const chooseOrder = (res) => {
        setState(prevState => ({...prevState, client_id: res.client}))
        toggle()
    };

    const togglePaidFor = () =>  setIsPaidForOpen(!isPaidForOpen);

    const choosePaidFor = (res) => {
        setState(prevState => ({...prevState, paid_for_ids: [...prevState.paid_for_ids, res.client]}))
        togglePaidFor()
    };

    useEffect(() => {
        if (id || orderId)
            getEntry(id || orderId).then(res => setState(() => res))
        getCurrencies({}).then(res => setCurrencies(() => res));
    }, [id, state.paid_for_ids.length])

    const createOrUpdate = () => {
        const { transaction_status, ...order } = {...state, paid_to_total: ((state.paid_value || 0) / (state.total_value || 1)) * 100}
        return (id || orderId? editEntry(id || orderId, order) : createEntry(order)).then(res => {
            onClickCb ? onClickCb(res) : history.push('/administration/orders/')
        })
    }

    return <TableCard isInline={isInline}>
        {isOpen && <ViewModal triggerModal={toggle} name={'select_order'} id={'select_order'} isOpened={isOpen}
                              onClick={chooseOrder}/>}
        {isPaidForOpen &&
        <ViewModal triggerModal={togglePaidFor} name={'paid_for'} id={'paid_for'} isOpened={isPaidForOpen}
                   onClick={choosePaidFor}/>}
        <div className={"row justify-content-end mb-3"}>
            <div className={"col-"}>
                <ElementWithPermissions disabled={!permissions.edit}>
                    <Button color="success" size={'sm'} className={'btn-rounded'} onClick={createOrUpdate}>
                        {t('management.orders.order.save')}
                    </Button>
                </ElementWithPermissions>
            </div>
        </div>
        <Row>
            <Col xs={12} className={'mb-3'}>
                <Row className={'mb-2'}>
                    {!(id || orderId) && <Col className={'col- align-self-center mb-2'}>
                        <Button color='primary' size={'sm'} className={'btn-rounded'} onClick={toggle}>
                            {t('management.orders.order.assignPayer')}
                        </Button>
                    </Col>}
                    <Col xs={12}>
                        <OrderTable data={[state]}/>
                    </Col>
                </Row>
            </Col>
            <Col className={'mt-3'} xs={12} md={6}>
                <Row>
                    {!(id || orderId) && <Col xs={12} className={'mb-3'}>
                        <Button color='primary' size={'sm'} className={'btn-rounded'} onClick={togglePaidFor}>
                            {t('management.orders.order.addCustomersToPayment')}
                        </Button>
                    </Col>}
                    {!!state.paid_for_ids.length && <Col xs={12}>
                        <FormGroup>
                            <Label>{t('management.orders.order.paidFor')}</Label>
                            <OrderTable data={state.paid_for_ids} isClient setState={setState} isNew={!state.id} productType={product_type}
                                        orderId={orderId || state.id} onAnnulationCb={onAnnulationCb} productId={productId || state.arrival_id?.id || state.slot_id?.id || state.group_id?.id}
                                        presetFilters={presetFilters}/>
                        </FormGroup>
                    </Col>}
                </Row>
            </Col>
            <Col className={'mt-3'} xs={12} md={6}>
                <Form>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <CustomInput type='checkbox' className={'custom-switch'} id='is_waiting'
                                             name='is_waiting'
                                             label={t('management.orders.order.inQueue')}
                                             checked={state.is_waiting || false} onChange={setOrderData}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                {!state.id ?
                                    <CustomInput type='checkbox' className={'custom-switch'} id='has_paid'
                                                 name='has_paid'
                                                 label={t('management.orders.order.paid')} checked={state.has_paid || false}
                                                 onChange={setOrderData}
                                    />
                                    : <ElementWithPermissions disabled={!permissions.edit}>
                                         <button type={"button"} onClick={changePaymentStatus}
                                                 className={'btn btn-primary btn-rounded mr-2 mb-2'}>
                                            {!!state.has_paid ? t('management.orders.order.cancelPayment') : t('management.orders.order.pay')}
                                         </button></ElementWithPermissions>}
                                {state.id && state.payments.length > 1 && <ElementWithPermissions disabled={!permissions.edit || state.order_status === 'approved'}>
                                    <button type={"button"} onClick={changePartialPayInFull}
                                            className={'btn btn-primary btn-rounded mb-2'}>
                                        {t('management.orders.order.payInFull')}
                                    </button></ElementWithPermissions>}
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} lg={4} xl={2}>
                                    <FormGroup>
                                        <Label>
                                            {t('management.orders.order.percent')}
                                        </Label>
                                        <Input name={'paid_to_total'}
                                               value={((state.paid_value || 0) / (state.total_value || 1)) * 100 + '%'}
                                               disabled/>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} xl={8}>
                                    <Row>
                                        <Col xs={12} lg={4} xl={4}>
                                            <FormGroup>
                                                <Label>
                                                    {t('management.orders.order.money')}
                                                </Label>
                                                <CurrencyInput name={'real_money'}
                                                               value={state.real_money ?? ''}
                                                               onChange={setCurrencyData} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} lg={4} xl={4}>
                                            <FormGroup>
                                                <Label>
                                                    {t('management.orders.order.entered')}
                                                </Label>
                                                <CurrencyInput name={'paid_value'}
                                                               value={state.paid_value || ''}
                                                               onChange={setCurrencyData}/>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} lg={4} xl={4}>
                                            <FormGroup>
                                                <Label>
                                                    {t('management.orders.order.price')}
                                                </Label>
                                                <CurrencyInput name={'total_value'}
                                                               value={state.total_value || ''}
                                                               onChange={setCurrencyData}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} lg={4} xl={2}>
                                    <FormGroup>
                                        <Label>
                                            {t('management.orders.order.currency')}
                                        </Label>
                                        <Input type="select" id={'payment_currency'} name={'payment_currency'}
                                               value={state.payment_currency} disabled={!!product_url}
                                               onChange={setOrderData}>
                                            {currencies.map(currency => <option key={currency.sign}
                                                                                value={currency.key}>{currency.sign}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={4}>
                            <FormGroup>
                                <Label>
                                    {t('management.orders.order.productType')}
                                </Label>
                                <Input type={'select'} name={'product_type'} value={state.product_type}
                                       onChange={setOrderData} disabled={!!product_url}>
                                    <option value={'slots'}>{t('management.orders.order.slots')}</option>
                                    <option value={'arrivals'}>{t('management.orders.order.arrivals')}</option>
                                    <option value={'groups'}>{t('management.orders.order.groups')}</option>
                                    <option value={'subscriptions'}>{t('management.orders.order.subscriptions')}</option>
                                    <option value={'practices'}>{t('management.orders.order.arrivals')}</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={8}>
                            <FormGroup>
                                <Label>
                                    {t('management.orders.order.productUrl')}
                                </Label>
                                <Input name={'product_url'}
                                       value={state.arrival_id?.url || state.slot_id?.url || state.group_id?.url || state.product_url}
                                       onChange={setOrderData} disabled={!!product_url}/>
                            </FormGroup>
                        </Col>
                        {(id || orderId) &&  <CustomFormControls productType={product_type} state={state} setState={setOrderData}/>}
                        {
                            (id || orderId) &&
                            <Col xs={12} lg={5}>
                                <FormGroup>
                                    <Label>
                                        {t('management.orders.order.paymentTime')}
                                    </Label>
                                    <Input disabled value={datetime(state.created_at)}/>
                                </FormGroup>
                            </Col>
                        }
                        <Col xs={12} lg={7}>
                            <FormGroup>
                                <Label>
                                    {t('management.orders.order.userComment')}
                                </Label>
                                <Input name={'comment'}
                                       value={state.comment}
                                       onChange={setOrderData} />
                            </FormGroup>
                        </Col>
                        {(state.promocode_applied || state.certificate_applied || state.subscription_applied) &&
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>
                                        {t('management.orders.order.discountInfo')}
                                    </Label>
                                {state.promocode_applied &&
                                    <div>
                                        {t('management.orders.order.promoCode')}: <b> {state.promocode_applied.name} </b>
                                        <CopyToClipboard text={state.promocode_applied.name}
                                                         onCopy={() => toast(t('management.orders.order.promoCodeCopied'), {type: 'success'})}>
                                            <TiTicket size={'20px'} color={'green'}/>
                                        </CopyToClipboard>
                                        <span className="ml-1">- { state.promocode_applied.discount_size}
                                            {state.promocode_applied.discount_type === 'percent'
                                                ? '%'
                                                : ` ${state.promocode_applied.discount_currency}`
                                            }
                                        </span>
                                    </div>
                                }
                                {state.certificate_applied &&
                                    <div>
                                        {t('management.orders.order.certificate')}: <b> {state.certificate_applied.name} </b>
                                        <CopyToClipboard text={state.certificate_applied.name}
                                                         onCopy={() => toast(t('management.orders.order.certificateCopied'), {type: 'success'})}>
                                            <AiOutlineBook size={'20px'} color={'green'}/>
                                        </CopyToClipboard>
                                        <span className="ml-1">
                                            - { state.certificate_sum}&nbsp;{state.certificate_applied.price_currency}
                                        </span>
                                    </div>
                                }
                                {state.subscription_applied &&
                                    <div>
                                        {t('management.orders.order.subscription')}: <b> {state.subscription_applied.name} </b>
                                        <CopyToClipboard text={state.subscription_applied.name}
                                                         onCopy={() => toast(t('management.orders.order.subscriptionCopied'), {type: 'success'})}>
                                            <AiOutlineWallet size={'20px'} color={'green'}/>
                                        </CopyToClipboard>
                                        <span className="ml-1">
                                            - { state.deposit_sum}&nbsp;{state.payment_currency}
                                        </span>
                                    </div>
                                }
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                </Form>
            </Col>
        </Row>
    </TableCard>
})
export default Order;