import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDefaultEndpoints} from "../../../../../../lib/api/useDefaultEndpoints";
import {DataTable, field} from "../../../../../generic/tables";
import {datetime} from "../../../../../../lib/utils/format";
import {TiArrowSortedDown, TiArrowSortedUp} from 'react-icons/ti';
import {TableCard} from "../../../../../generic/cards";
import SlotHrefAdmin from "../../../../../common/hrefGenerators/SlotHrefAdmin";
import {Col, Row} from "reactstrap";
import SubscriptionChangeModal from "./SubscriptionChangeModal";
import {ElementWithPermissions} from "../../../../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import ElementWithAccessInCountries from '../../../../../../lib/utils/hocs/ElementWithAccessInCountries';
import getCountryValue from '../../../../../../lib/utils/getCountryValue';

const reasonToLabel = ({reason}) => {
    switch (reason) {
        case 'purchase':
            return t('management.users.purchaseSemicolon');
        case 'recharge':
            return t('management.users.rechargeSemicolon');
        case 'manual':
            return t('management.users.manualSemicolon');
        default:
            return reason
    }
};

const Subscription = ({state, dispatch}) => {
    const {findAndCountEntries} = useDefaultEndpoints('subscription-changes');
    const [isModalOpen, setModalOpen] = useState(false);
    const triggerModal = () => setModalOpen(isOpen => !isOpen);
    const [reload, setReload] = useState(false);
    const permissions = useRoleSystem(ENTITIES.CLIENTS)
    useEffect(() => setReload(rel => !rel), [state.deposit_sum, state.subscription_id?.id])

    return <>
        <SubscriptionChangeModal triggerModal={triggerModal} isOpened={isModalOpen} dispatch={dispatch} state={state}/>
        <Row style={{justifyContent: 'space-evenly'}}>
            <div className={'badge badge-outline-dark mr-1 mb-3'} style={{textAlign: 'start', borderRadius: '10px'}}>
                <Col xs={'auto'}>
                    <Row style={{justifyContent: 'space-between'}}>
                        <Col xs={'auto'}>
                            <h1 className="display-4 mb-0 text-dark">{t('management.users.size')}:</h1>
                        </Col>
                        <Col className={'pr-0 flex-grow-0'}>
                            <h1 className="display-4 mb-0 text-dark">
                                <b>{state.deposit_sum || 0} {state.subscription_id?.price_currency || ''}</b></h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={'auto'}>
                            <h1 className="display-4 mb-0 text-dark">{t('management.users.totalDiscount')}:</h1>
                        </Col>
                        <Col className={'pr-0'}>
                            <h1 className="display-4 mb-0 text-dark">
                                <b>{state.subscription_id?.keel_all_luch_group_discount_size || 0}%</b></h1>
                        </Col>
                    </Row>
                    <ElementWithAccessInCountries hideInCountries={['EN']}>
                      <Row>
                        <Col xs={'auto'}>
                          <h1 className="display-4 mb-0 text-dark">{t('management.users.individualDiscount')}:</h1>
                        </Col>
                        <Col className={'pr-0'}>
                          <h1 className="display-4 mb-0 text-dark">
                            <b>{state.subscription_id?.luch_individual_discount_size || 0}%</b></h1>
                        </Col>
                      </Row>
                    </ElementWithAccessInCountries>
                    <ElementWithAccessInCountries hideInCountries={['EN']}>
                        <Row style={{justifyContent: 'space-between'}}>
                            <Col xs={'auto'}>
                                <h1 className="display-4 mb-0 text-dark">{t('management.users.racingDiscount')}:</h1>
                            </Col>
                            <Col className={'pr-0 flex-grow-0'}>
                                <h1 className="display-4 mb-0 text-dark">
                                    <b>{state?.subscription_id?.race_discount_size || 0}%</b></h1>
                            </Col>
                        </Row>
                    </ElementWithAccessInCountries>
                    <Row style={{justifyContent: 'space-between'}}>
                        <Col xs={'auto'}>
                            <h1 className="display-4 mb-0 text-dark">{t('management.users.racingDiscountCup')}:</h1>
                        </Col>
                        <Col className={'pr-0 flex-grow-0'}>
                            <h1 className="display-4 mb-0 text-dark">
                                <b>{state?.subscription_id?.cup_discount_size || 0}%</b></h1>
                        </Col>
                    </Row>
                    <ElementWithAccessInCountries hideInCountries={['RU']}>
                      <Row>
                        <Col xs={'auto'}>
                          <h1 className="display-4 mb-0 text-dark">{t('management.users.sailGPDiscount')}:</h1>
                        </Col>
                        <Col className={'pr-0'}>
                          <h1 className="display-4 mb-0 text-dark text-right">
                            <b>{state.subscription_id?.sail_gp_discount_size || 0}%</b></h1>
                        </Col>
                      </Row>
                    </ElementWithAccessInCountries>
                </Col>
            </div>

            <Col xs={'auto'}>
                <Row className={'mb-3'}>
                    <Col xs={'auto'}>
                        <h1 className="display-4 mb-0 text-dark">{t('management.users.subscription')}: </h1>
                    </Col>
                    <Col xs={'auto'}>
                        <h1 className="display-4 mb-0 text-dark"><b>{state.subscription_id?.name || t('management.users.notSelected')}</b>
                        </h1>
                    </Col>
                </Row>
                <Row style={{justifyContent: 'center'}}>
                    <Col xs={'auto'}>
                        <ElementWithPermissions disabled={!permissions.edit}>
                            <button className={'btn btn-rounded btn-inverse-warning mb-2'} onClick={triggerModal}>
                                {t('management.users.change')}
                            </button>
                        </ElementWithPermissions>
                    </Col>
                </Row>
            </Col>

        </Row>


        <Row>
            <Col xs={12}>
                <TableCard isInline title={t('management.users.changeHistory')}>
                    <DataTable findAndCount={findAndCountEntries}
                               filters={{client_id: state.id}}
                               fields={[
                                   field(t('management.users.changeMagnitude'), true, "value_change", res => <>
                                       {res.value_change} &nbsp; {res.value_change >= 0
                                       ? <TiArrowSortedUp size={'20px'} color={'green'}/>
                                       : <TiArrowSortedDown size={'20px'} color={'red'}/>}</>
                                   ),

                                   field(t('management.users.changeReason'), true, "reason", res => {
                                       const reason = reasonToLabel(res);
                                       return res.reason === 'manual'
                                           ? <> {reason}
                                               <small>{`${t('management.users.operator')}: ${res.operator?.first_name} ${res.operator?.last_name}`}</small></>
                                           : reason
                                   }),
                                   field(t('management.users.product'), false, "order_id", res => {
                                       if (res.product_type === 'slots')
                                           return <SlotHrefAdmin
                                               productUrl={res.product_url}
                                               linkName={res.product_name}
                                           />
                                       return res.subscription_id?.name;
                                   }),
                                   field(t('management.users.time'), true, "created_at", res =>
                                       <>{datetime(res.created_at)}</>
                                   ),
                                   field(t('management.users.comment'), true, "comment"),
                               ]}
                               sortField="created_at"
                               sortDir="desc"
                               name="subscription-changes"
                               reload={reload}
                    />
                </TableCard>
            </Col>
        </Row>


    </>
}

Subscription.propTypes = {
    state: PropTypes.object.isRequired,
}

export default Subscription;


