import React, { useEffect } from 'react';
import {Col, CustomInput, Form, FormGroup, Row} from "reactstrap";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {Controller} from "react-hook-form";
import PriceCurrency from "../../common/reactHookFormsFields/PriceCurrency";
import {SelectControllerRHF} from "../../generic/fields";
import {COUNTRIES_LIST} from "sv-common/constants/bases";
import {t} from "i18next";
import getCountryValue from '../../../lib/utils/getCountryValue';
import { COUNTRY } from '../../../lib/utils/constants';
import ElementWithAccessInCountries from '../../../lib/utils/hocs/ElementWithAccessInCountries';
import CurrencyControllerRHF from "../../generic/fields/CurrencyControllerRHF";

const COUNTRY_OPTIONS = COUNTRIES_LIST.map((c, i) => <option key={i} value={c.key}>{c.value}</option>)

const EditModalChildren = ({entity, setEntity, control, errors, handleInput}) => {
    useEffect(() => {
      setEntity(_entity => ({ ..._entity, country: _entity.country || COUNTRY }));
    }, [entity.id]);

    return <Form>
        <Row>
            <Col xs={12} md={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="name"
                        defaultValue={entity.name || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                                 label={t('directories.subscriptions.editModalChildren.nameInputLabel')}
                                                 type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('directories.subscriptions.editModalChildren.nameInputPlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="key"
                        defaultValue={entity.key || ''}
                        rules={{
                            required: t('inputs.validation.required'),
                            pattern: {
                                value: /^[A-za-z0-9_-]+$/,
                                message: t('directories.subscriptions.editModalChildren.keyInputValidation')
                            }
                        }}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                                 label={t('directories.subscriptions.editModalChildren.keyInputLabel')}
                                                 type="text" onBlur={onBlur} value={value} placeholder="abonement_3000"
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={3}>
                <FormGroup>
                    <CurrencyControllerRHF inputName={'keel_all_luch_group_discount_size'} control={control} errors={errors}
                                           defaultValue={entity.keel_all_luch_group_discount_size || ''} isRequired
                                           rules={{required: t('inputs.validation.required')}}
                                           label={t('directories.subscriptions.commonDiscount')}
                                           placeholder="20" setValue={e => handleInput(e, setEntity).value}/>
                </FormGroup>
            </Col>
            <ElementWithAccessInCountries hideInCountries={['EN']}>
              <Col xs={12} md={3}>
                <FormGroup>
                   <CurrencyControllerRHF inputName={'luch_individual_discount_size'} control={control} errors={errors}
                                           defaultValue={entity.luch_individual_discount_size || ''} isRequired
                                           rules={{required: t('inputs.validation.required')}}
                                           label={t('directories.subscriptions.indivDiscount')}
                                           placeholder="23" setValue={e => handleInput(e, setEntity).value}/>
                </FormGroup>
              </Col>
            </ElementWithAccessInCountries>
            <Col xs={12} md={3}>
                <FormGroup>
                    <CurrencyControllerRHF inputName='cup_discount_size'
                                           control={control} errors={errors}
                                           defaultValue={entity.cup_discount_size}
                                           isRequired rules={{required: t('inputs.validation.required')}}
                                           label={t('directories.subscriptions.editModalChildren.raceDiscountCup')}
                                           placeholder="23" setValue={e => handleInput(e, setEntity).value}/>
                </FormGroup>
            </Col>
            <ElementWithAccessInCountries hideInCountries={['EN']}>
                <Col xs={12} md={3}>
                    <FormGroup>
                        <CurrencyControllerRHF inputName='race_discount_size'
                                               control={control} errors={errors}
                                               defaultValue={entity.race_discount_size}
                                               isRequired rules={{required: t('inputs.validation.required')}}
                                               label={t('directories.subscriptions.editModalChildren.raceDiscount')}
                                               placeholder="23" setValue={e => handleInput(e, setEntity).value}/>
                    </FormGroup>
                </Col>
            </ElementWithAccessInCountries>
            <ElementWithAccessInCountries hideInCountries={['RU']}>
              <Col xs={12} md={3}>
                <FormGroup>
                    <CurrencyControllerRHF inputName='sail_gp_discount_size'
                                           control={control} errors={errors}
                                           defaultValue={entity.sail_gp_discount_size}
                                           isRequired rules={{required: t('inputs.validation.required')}}
                                           label={t('directories.subscriptions.editModalChildren.sailGPDiscount')}
                                           placeholder="23" setValue={e => handleInput(e, setEntity).value}/>
                </FormGroup>
              </Col>
            </ElementWithAccessInCountries>
            <Col xs={12} md={3}>
                <FormGroup>
                    <CurrencyControllerRHF inputName={'price_value'}
                                           control={control} errors={errors}
                                           defaultValue={entity.price_value || ''}
                                           isRequired rules={{required: t('inputs.validation.required')}}
                                           label={t('directories.subscriptions.price')}
                                           placeholder="3000" setValue={e => handleInput(e, setEntity).value}/>
                </FormGroup>
            </Col>
            <Col xs={12} md={3}>
                <FormGroup>
                    <PriceCurrency form={{control, errors}} setState={e => handleInput(e, setEntity)} state={entity}
                                   isRequired/>
                </FormGroup>
            </Col>
            <Col xs={12} md={3}>
                <FormGroup>
                    <SelectControllerRHF control={control} errors={errors} label={t('directories.subscriptions.country')}
                                         isRequired inputName={'country'} defaultValue={entity.country || COUNTRY}
                                         options={COUNTRY_OPTIONS} setValue={e => handleInput(e, setEntity)} />
                </FormGroup>
            </Col>
            <Col xs={12} md={3}>
                <FormGroup>
                    <CurrencyControllerRHF inputName={'nominal_value'}
                                           control={control} errors={errors}
                                           defaultValue={entity.nominal_value || ''}
                                           isRequired rules={{required: t('inputs.validation.required')}}
                                           label={t('inputs.nominal.label')}
                                           placeholder="3000" setValue={e => handleInput(e, setEntity).value}/>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <CustomInput type="checkbox" className={'custom-switch'} id="active" name="active"
                                         defaultChecked={true}
                                         label={t('inputs.active.label')} checked={entity.active}
                                         onChange={e => handleInput(e, setEntity).value}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <CustomInput type="checkbox" className={'custom-switch'} id="is_available_for_sale"
                                         name="is_available_for_sale" isRequired={true} defaultChecked={false}
                                         label={t('inputs.saleAvailable.label')} checked={entity.is_available_for_sale}
                                         onChange={e => handleInput(e, setEntity).value}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Form>
}

export default EditModalChildren;
