import React, {useEffect} from 'react';
import {Controller} from "react-hook-form";
import FieldWithValidation from "./FieldWithValidation";

const CurrencyControllerRHF = ({
                               inputName,
                               control,
                               defaultValue = '',
                               rules,
                               errors,
                               label,
                               setValue,
                               placeholder,
                               isRequired,
                               setValueRHF,
                               type='text',
                               isDeleteSpaces,
                               isFloat = false,
                               ...rest
                           }) => {

    useEffect(() => {
        if (setValueRHF)
            setValueRHF(inputName, defaultValue)
    }, [defaultValue])

    return (
        <Controller
            control={control}
            name={inputName}
            defaultValue={defaultValue}
            rules={rules}
            render={(
                {value, onBlur, name, ref, onChange},
                {invalid}
            ) =>
                <FieldWithValidation invalid={invalid} errors={errors} label={label}
                                     name={name} isRequired={isRequired}
                                     type={type} onBlur={onBlur} value={value} placeholder={placeholder}
                                     onChange={e => {
                                         const regexp = isFloat ? /^\d+[.,]?(\d{1,2})?$/ : /^\d+$/
                                         if (!e.target.value.length || regexp.test(e.target.value)) {
                                             onChange(e.target.value.replace(',', '.'));
                                             setValue && setValue(e);
                                         }
                                     }}
                                     {...rest}
                />
            }
        />
    )
}

export default CurrencyControllerRHF;