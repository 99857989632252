import React, {useEffect, useState} from 'react'
import {Col, CustomInput, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {
    ConditionalField,
    DatePickerRHF,
    TextControllerRHF
} from "../../../generic/fields";
import {GROUP_TYPES_MAP} from "sv-common/constants/groups";
import Schedule from "./theoryBlocks/Schedule";
import Params from "./theoryBlocks/Params";
import {
    LegalEntitiesRHF,
    PriceCurrencyRHF,
    PublicContractsRHF, TimePickerRHF,
    TimeZonePickerRHF
} from "../../../common/reactHookFormsFields";
import {requiredRule} from "../../../common/reactHookFormsFields/rules";
import PropTypes from 'prop-types';
import AdditionalInfo from "./theoryBlocks/AdditionalInfo";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {t} from "i18next";
import CheckSelector from '../../../generic/fields/CheckSelector';
import { CHECK_TYPES } from 'sv-common/constants/checks';
import getCountryValue from '../../../../lib/utils/getCountryValue';
import TeachersInput from '../../inputs/TeachersInput';
import LessonsPerDayInput from "../../../common/reactHookFormsFields/LessonsPerDayInput";

dayjs.extend(customParseFormat)

export const getCityOptions = () => (getCountryValue({
  ru: [
    <option value={t('school.theory.cities.online')} id={'online'}>{t('school.theory.cities.online')}</option>,
    <option value={t('school.theory.cities.moscow')}>{t('school.theory.cities.moscow')}</option>,
    <option value={t('school.theory.cities.saintPetersburg')}>{t('school.theory.cities.saintPetersburg')}</option>,
    <option value={t('school.theory.cities.sochi')}>{t('school.theory.cities.sochi')}</option>,
    <option value={t('school.theory.cities.yaroslavl')}>{t('school.theory.cities.yaroslavl')}</option>,
    <option value={t('school.theory.cities.kazan')}>{t('school.theory.cities.kazan')}</option>,
    <option value={t('school.theory.cities.vladivostok')}>{t('school.theory.cities.vladivostok')}</option>,
    <option value={t('school.theory.cities.minsk')}>{t('school.theory.cities.minsk')}</option>,
    <option value={t('school.theory.cities.nizhniyNovgorod')}>{t('school.theory.cities.nizhniyNovgorod')}</option>,
    <option value={t('school.theory.cities.turkey')}>{t('school.theory.cities.turkey')}</option>,
  ],
  en: [
    <option value={t('school.theory.cities.portRashid')}>{t('school.theory.cities.portRashid')}</option>,
  ]
}) || [])

function Controller() {
    return null;
}

Controller.propTypes = {
    defaultValue: PropTypes.any,
    name: PropTypes.string,
    rules: PropTypes.shape({required: PropTypes.string}),
    render: PropTypes.func
};
const EditModalChildren = ({entity, setEntity, errors, control, handleInput}) => {
    const [cSelected, setCSelected] = useState([]);
    const onCheckboxBtnClick = (selected) => {
        const index = cSelected.indexOf(selected);
        if (index < 0) cSelected.push(selected);
        else cSelected.splice(index, 1);
        setCSelected([...cSelected]);
    };

    useEffect(() => {
        setEntity(entity => ({...entity, weekdays: cSelected}))
    }, [cSelected.length]);

    const selectOptions = getCityOptions();

    useEffect(() => {
      if (selectOptions.length === 1) {
        handleInput({ target: { name: 'place', value: selectOptions[0].props.value } }, setEntity);
      }
    }, [selectOptions.length]);

    return <Form>
        <Row>
            <ConditionalField condition={entity.type === GROUP_TYPES_MAP.THEORY}>
                <Params setEntity={setEntity} errors={errors} handleInput={handleInput}
                        entity={entity} control={control}/>
            </ConditionalField>

            <Col xs={6} lg={4}>
                <FormGroup>
                    <Label>{t('school.theory.editModalChildren.online')}</Label>
                    <CustomInput type="checkbox" className={'custom-switch'} id="is_online" name="is_online"
                                 checked={entity.is_online || false}
                                 onChange={e => handleInput(e, setEntity).value}

                    />
                </FormGroup>
            </Col>
            <Col xs={6} lg={4}>
                <FormGroup>
                    <Label>{t('school.theory.editModalChildren.place')}</Label>
                    <Input type={'select'}
                           name={'place'} disabled={entity.is_online}
                           placeholder={t('school.theory.editModalChildren.moscow')}
                           value={entity.is_online ? t('school.theory.editModalChildren.online') : entity.place}
                           onChange={e => handleInput(e, setEntity)}
                    >
                        {selectOptions}
                    </Input>
                </FormGroup>
            </Col>
            <Col xs={6} md={4}>
                <FormGroup>
                    <TextControllerRHF inputName={"address"} control={control} setValue={e => handleInput(e, setEntity)}
                                       defaultValue={(entity.is_online ? t('school.theory.editModalChildren.online') : entity.address) || ''}
                                       label={t('school.theory.editModalChildren.address')} isRequired errors={errors}
                                       rules={requiredRule} placeholder={t('school.theory.editModalChildren.moscow')}/>
                </FormGroup>
            </Col>
            <Col xs={6} lg={4}>
                <FormGroup>
                    <Label>{t('school.theory.editModalChildren.totalSits')}</Label>
                    <Input type={'text'} name={'total_sits'} placeholder="10" value={entity.total_sits}
                           onChange={e => handleInput(e, setEntity)}/>
                </FormGroup>
            </Col>
            <ConditionalField condition={entity.type === 'theory'}>
                <Schedule cSelected={cSelected} onCheckboxBtnClick={onCheckboxBtnClick} entity={entity}/>
            </ConditionalField>
            <Col xs={6} lg={4}>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Label>{t('school.theory.editModalChildren.startDate')} <span style={{color: 'red'}}>*</span></Label>
                        </Col>
                        <Col xs={6}>
                            <DatePickerRHF isRequired control={control} defaultValue={entity.start_date} errors={errors}
                                           inputName={'start_date'} rules={requiredRule}
                                           setValue={date => setEntity(entity => ({...entity, start_date: date}))}/>

                        </Col>
                        <Col xs={6}>
                            <TimePickerRHF
                                setEntity={setEntity} control={control} errors={errors} isRequired
                                defaultValue={entity.is_intensive ? '10:00' : entity.start_time}
                                name="start_time"/>
                        </Col>
                    </Row>
                </FormGroup>
            </Col>
            {entity.is_intensive &&
                <Col xs={6} lg={6}>
                    <LessonsPerDayInput
                        name={'lessons_per_day'} label={t('school.theory.editModalChildren.lessons_per_day')}
                        setEntity={setEntity} control={control} errors={errors} isRequired
                    />
                </Col>
            }
            <Col xs={entity.is_intensive ? 6 : undefined}
                 lg={entity.is_intensive ? 6 : { offset: entity.type === GROUP_TYPES_MAP.LECTURE ? 0 : 8, size: 4 }}>
                <FormGroup>
                    <TimeZonePickerRHF form={{control, errors}} setState={e => handleInput(e, setEntity)}
                                       state={entity} isRequired/>
                </FormGroup>
            </Col>
            <Col xs={8} md={2}>
                <FormGroup>
                    <Label>{t('school.theory.editModalChildren.price')}</Label>
                    <Input type={'text'} name={'price_value'} placeholder="10" value={entity.price_value}
                           onChange={e => handleInput(e, setEntity)}/>
                </FormGroup>
            </Col>
            <Col xs={4} md={2}>
                <FormGroup>
                    <PriceCurrencyRHF form={{control, errors}} setState={e => handleInput(e, setEntity)}
                                      state={entity} isRequired/>
                </FormGroup>
            </Col>
            <Col xs={12} md={4}>
                <FormGroup>
                    <LegalEntitiesRHF form={{errors, control}} setState={e => handleInput(e, setEntity)}
                                      state={entity} isRequired/>
                </FormGroup>
            </Col>
            <Col xs={12} md={4}>
                <FormGroup>
                    <PublicContractsRHF form={{errors, control}} setState={e => handleInput(e, setEntity)}
                                        state={entity} isRequired/>
                </FormGroup>
            </Col>
            <TeachersInput entity={entity} setEntity={setEntity} />
            <Col xs={6} md={4}>
                <FormGroup>
                    <TextControllerRHF inputName={"tg_link"} setValue={e => handleInput(e, setEntity)}
                                       defaultValue={entity.tg_link}
                                       label={t('school.theory.editModalChildren.tgLink')}
                                       control={control} errors={errors}
                                       placeholder={'https://t.me/...'}/>
                </FormGroup>
            </Col>
            <Col xs={6} md={4}>
                <FormGroup>
                    <TextControllerRHF inputName={"platform_url"} setValue={e => handleInput(e, setEntity)}
                                       defaultValue={entity.platform_url}
                                       label={t('school.theory.editModalChildren.platform')}
                                       control={control} errors={errors}
                                       placeholder={'https://...'}/>
                </FormGroup>
            </Col>
            <Col xs={6} md={4}>
                <FormGroup>
                    <TextControllerRHF inputName={"platform_auth_code"} setValue={e => handleInput(e, setEntity)}
                                       defaultValue={entity.platform_auth_code}
                                       label={t('school.theory.editModalChildren.platformCode')}
                                       control={control} errors={errors}
                                       placeholder={'1234'}/>
                </FormGroup>
            </Col>
            <Col xs={6} lg={4}>
                <FormGroup>
                    <Label>{t('school.theory.editModalChildren.promocodesActive')}</Label>
                    <CustomInput type="checkbox" className={'custom-switch'} id="are_promocodes_active"
                                 name="are_promocodes_active" checked={entity.are_promocodes_active || false}
                                 onChange={e => handleInput(e, setEntity).value}/>
                </FormGroup>
            </Col>
            <Col xs={6} lg={4}>
                <FormGroup>
                    <Label>{t('school.theory.editModalChildren.paidLocally')}</Label>
                    <CustomInput type="checkbox" className={'custom-switch'} id="paid_locally"
                                 name="paid_locally" checked={entity.paid_locally || false}
                                 onChange={e => handleInput(e, setEntity).value}/>
                </FormGroup>
            </Col>
            <Col xs={6} lg={4}>
                <FormGroup>
                    <Label>{t('school.theory.editModalChildren.checkAge')}</Label>
                    <CustomInput type="checkbox" className={'custom-switch'} id="check_age_required"
                                 name="check_age_required" checked={entity.check_age_required || false}
                                 onChange={e => handleInput(e, setEntity).value}/>
                </FormGroup>
                {entity.check_age_required &&
                    <FormGroup>
                        <Label for="check_age_value">{t('school.theory.editModalChildren.age')}</Label>
                        <Input type="text" name="check_age_value" id="check_age_value" placeholder="16"
                               value={entity.check_age_value || ''} onChange={e => handleInput(e, setEntity).value}
                        />
                    </FormGroup>
                }
            </Col>
            <Col xs={6} lg={4}>
              <CheckSelector dispatch={({ payload }) => handleInput(payload, setEntity)} control={control} errors={errors} checkTypes={CHECK_TYPES} />
            </Col>
            <Col xs={6} lg={4}>
                <FormGroup>
                    <TextControllerRHF inputName={"quiz_url"} setValue={e => handleInput(e, setEntity)}
                                       defaultValue={entity.quiz_url}
                                       label={t('school.theory.editModalChildren.quizLink')}
                                       control={control} errors={errors}
                                       placeholder={'https://...'}/>
                </FormGroup>
            </Col>
            <Col xs={6} lg={4}>
                <FormGroup>
                    <TextControllerRHF inputName={"url_calendar"} setValue={e => handleInput(e, setEntity)}
                                       defaultValue={entity.url_calendar}
                                       label={t('school.theory.editModalChildren.calendarUrl')}
                                       control={control} errors={errors}
                                       placeholder={'https://...'}/>
                </FormGroup>
            </Col>
            <Col xs={8}>
                <AdditionalInfo state={entity} setState={setEntity}/>
            </Col>
        </Row>
    </Form>
}

EditModalChildren.propTypes = {
    entity: PropTypes.object.isRequired,
    setEntity: PropTypes.func.isRequired,
    errors: PropTypes.any.isRequired,
    control: PropTypes.any.isRequired,
    handleInput: PropTypes.func.isRequired
};

export default EditModalChildren;
