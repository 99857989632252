import {Input} from "reactstrap";
import React from "react";

const CurrencyInput = ({name, value, onChange}) => {
    return (
        <Input name={name}
               value={value}
               onChange={(e) => {
                   const regexp = /^\d+[.,]?(\d{1,2})?$/
                   if (!e.target.value.length || regexp.test(e.target.value)) {
                       let {name, value} = e.target;
                       value = value.replace(',', '.');
                       onChange({name, value});
                   }
               }} />
    )
}



export default CurrencyInput