import {EVENT_TYPES_LANG_ADAPTER} from "sv-common/constants/events";
import {EVENT_TYPES_ALLOWED} from "../../../../lib/utils/constants";
import {defaultValueTypeHandler} from "./utils";

export const getEventTypes = (language) =>
    EVENT_TYPES_ALLOWED.map(eventType => ({value: eventType, label: EVENT_TYPES_LANG_ADAPTER[language][eventType]}));

const getLocalizedEventTypes = (lang) => EVENT_TYPES_ALLOWED.map(eventType =>
    ({value: eventType, label: EVENT_TYPES_LANG_ADAPTER[lang][eventType]}))

export const typeToLabel = (type, lang = 'ru') =>
    getLocalizedEventTypes(lang).filter(eventType => eventType.value === type)[0]?.label;


const initialState = (baseUrl) => ( {
    baseUrl: baseUrl,
    boat_class: '',
    available_boats_limit: 0,
    type: '',
    name: '',
    duration: '',
    price_value: '',
    price_currency: 'RUB',

    is_coach_required: false,
    is_coach_selectable: false,
    is_full_book_available: false,
    is_own_boat: false,
    promo_subscription_available: false,
    team_only: false,
    is_private: false,
    is_closed: false,
    check_age_14: true,
    paid_locally: false,
    color: '#000000',

    full_book_discount_type: 'summ',
    full_book_discount_size: 0.0,
    full_book_discount_info: '',
    coachless_discount_type: 'summ',
    coachless_discount_size: 0.0,
    coachless_discount_info: '',
    own_boat_discount_type: 'summ',
    own_boat_discount_size: 0.0,
    own_boat_discount_info: '',

    comment: '',
    url: '',
    bring_guide_url: '',
    return_policy_url: '',
    public_contract_url: '',
    own_boat_public_contract_url: '',
    acquiring_key: '',

    are_promocodes_active: true,
    active_promocodes_ids: [],
    extra_materials: [],
    corpClients: [],

    loading: true
});

const reducer = (state, action) => {
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let defaultPreload = {};

        if (name === 'type') defaultPreload = defaultValueTypeHandler[value];

        return {
            [name]: value,
            ...defaultPreload,
        };
    }

    switch (action.type) {
        // Assistance block
        case 'setLoading':
            // value: false'
            return {
                ...state,
                loading: action.payload,
            }

        // Data editing block
        case 'setForm':
            // value: {exampleId: 'exampleKey'}
            return {
                ...state,
                loading: false,
                ...action.payload,
            }

        case 'setFormData':
            // value: SyntheticEvent
            return {
                ...state,
                ...handleInputChange(action.payload)
            }

        case 'clearDiscountData':
            // value: 'example'
            const discount = {}
            discount[action.payload + '_discount_type'] = 'summ'
            discount[action.payload + '_discount_size'] = 0.0
            discount[action.payload + '_discount_info'] = ''
            return {
                ...state,
                ...discount
            }

        case 'refreshFields':
            return {...initialState(state.baseUrl)}
        default:
            return state
    }
}

export {reducer, initialState};