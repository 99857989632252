import React, {useEffect, useState} from 'react';
import {TableCard} from "../../generic/cards";
import {Button, Col, FormGroup, Row} from "reactstrap";
import {RangedDatePicker} from "../../generic/fields";
import dayjs from "dayjs";
import {DataTable, field} from "../../generic/tables";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {BsGear} from "react-icons/bs";
import {useHistory} from 'react-router-dom';
import {EditModal, ModalConfirmation} from "../../generic/modals";
import EditModalChildren from './EditModalChildren';
import {daysAbbr, shortDate, time} from "../../../lib/utils/format";
import weekday from 'dayjs/plugin/weekday'
import {LoadBadge} from "../../generic/badges";
import {openInNewTab} from "../../../lib/utils/helper";
import {FaFile} from "react-icons/fa";
import _ from 'lodash';
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {ElementWithPermissions} from "../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import getCountryValue from '../../../lib/utils/getCountryValue';
import { CURRENCIES_KEYS } from 'sv-common/constants/currencies';
import {GROUP_TYPES_MAP} from "sv-common/constants/groups";
import {useRuleAccess} from "../../../lib/utils/frontRules";
import {GroupRemoveKeyHandler} from "../RuleProvider";

dayjs.extend(weekday)

const GroupsList = withBreadcrumbs(({type = 'theory'}) => {
    const {findAndCountEntries, deleteEntry} = useDefaultEndpoints('groups');
    const permissions = useRoleSystem(ENTITIES.GROUPS_THEORY);
    let history = useHistory();
    const { check } = useRuleAccess();

    const [reload, setReload] = useState(false);
    const [isModalOpen, toggleModal] = useState(false);
    const [groupToCopy, setGroupToCopy] = useState(undefined);
    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false);
    const [ejectedGroup, setEjectedGroup] = useState('');
    const [typeForRule, setTypeForRule] = useState('');
    const triggerModal = (group = {}) => {
        let {orders_ids, next_lesson_id, lessons_ids, has_started, has_custom_schedule, name, available_sits, url, id,
            reserved_sits, available_reserved_sits, weekdays, lessons_per_week, start_date, booked_to_total, status,
            booked_sits, ...sanitizedGroup} = group;
        setGroupToCopy(() => _.isEmpty(sanitizedGroup) ? undefined: {...sanitizedGroup, start_time: '19:30:00.000'})
        setReload(reload => !reload);
        toggleModal(isModalOpen => !isModalOpen)
    }
    const triggerModalConfirmation = () => toggleModalConfirmation(isModalConfirmationOpen => !isModalConfirmationOpen);
    const deleteGroup = () => permissions.edit
        ? (group) => {
            setEjectedGroup(() => group);
            triggerModalConfirmation()
        }
        : null
    const [daysFilter, setDaysFilter] = useState({
        from: dayjs().toDate(),
        to: dayjs().add(14, 'd').toDate()
    });
    const [filters, setFilters] = useState({
        start_date_gte: daysFilter.from, start_date_lte: daysFilter.to, name_ncontains: 'deleted'
    });
    const [rSelected, setRSelected] = useState('all');
    const [selected, setSelected] = useState({
        gims: type === 'theory' ? true : undefined,
        school: type === 'theory' ? true : undefined,
        not_started: true,
        started: true
    });

    const selectFilter = (e) => {
        const name = e.target.name
        setSelected(selected => ({...selected, [name]: !selected[name]}));
    }

    useEffect(() => {
        const setFilter = filter =>  setFilters(filters => ({...filters, ...filter}))
        if (selected.gims && selected.school)
            setFilter({ category_in: ['gims', 'school']})
        if (selected.gims && !selected.school)
            setFilter({ category_in: ['gims']})
        else if (!selected.gims && selected.school)
            setFilter({ category_in: ['school']})
        else
            setFilter({ category_in: undefined})


        if (selected.started && selected.not_started)
            setFilter({ has_started_in: [false, true]})
        else if (selected.started && !selected.not_started)
            setFilter({ has_started_in: [true]})
        else if (!selected.started && selected.not_started)
            setFilter({ has_started_in: [false]})
        else
            setFilter({ has_started_in: undefined})

    }, [selected.gims, selected.school, selected.started, selected.not_started])

    useEffect(() => {
        const setFilter = filter =>  setFilters(filters => ({...filters, ...filter}))

        if (rSelected === 'all')
            setFilter({ start_date_gte: dayjs().toDate(), start_date_lte: undefined, start_date_lt: undefined})
        if (rSelected === 'closest')
            setFilter({ start_date_gte: daysFilter.from, start_date_lte: daysFilter.to, start_date_lt: undefined})
        if (rSelected === 'archived')
            setFilter({ start_date_gte: undefined, start_date_lte: undefined, start_date_lt: dayjs().toDate()})

    }, [rSelected, daysFilter.to, daysFilter.from])

    useEffect(() => {
        if (type === GROUP_TYPES_MAP.THEORY) {
            setTypeForRule(GROUP_TYPES_MAP.THEORY);
        } else {
            setTypeForRule(GROUP_TYPES_MAP.LECTURE);
        }
    }, []);

    const onMiddleClick = (res) => openInNewTab(`/school/${type === "theory" ? "theory" : "lectures"}/group/view/${res.url}`)

    return <TableCard>
        {isModalConfirmationOpen && <ModalConfirmation onClose={triggerModalConfirmation} onConfirm={() => {
            deleteEntry(ejectedGroup.id).then(() => {
                setReload(reload => !reload);
                triggerModalConfirmation();
            })

        }} isOpen={isModalConfirmationOpen} hasCancel title={t('school.theory.groupsList.deleteGroup')}
                                                       text={t('school.theory.groupsList.exactlyDeleteGroup')}
                                                       cancelButtonText={t('school.theory.grouspList.noDeleteGroup')}
                                                       confirmButtonText={t('school.theory.groupsList.yesDeleteGroup')}
        />}
        {isModalOpen && <EditModal title={t('school.theory.groupsList.newGroupTheory')} entityName={'groups'}
           triggerModal={triggerModal} isOpened={isModalOpen} EditForm={EditModalChildren} size={'sm'}
           edit={permissions.edit} entry={
            groupToCopy
                ? {...groupToCopy}
                : {price_currency: getCountryValue({ ru: CURRENCIES_KEYS.RUB, en: CURRENCIES_KEYS.AED }), category: type === 'lecture' ? undefined : 'school',
                    place: t('school.theory.groupsList.moscow'), total_sits: 12, start_time: '19:30:00.000',
                    price_value: 31500, type, start_date: dayjs().toDate(), timezone: "+0300", are_promocodes_active: true, lessons_per_day: 1}
        } onSuccessCb={type === 'lecture' ? res => history.push(`/school/lectures/group/edit/${res.url}`) : undefined}
        />}
        <Row>
            {type === 'theory' && <Col xs={'auto'}>
                <FormGroup>
                    {t('school.theory.groupsList.products')}
                    <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'school'}
                            onClick={selectFilter} active={selected.school}>
                        {t('school.theory.groupsList.captainSchool')}
                    </Button>
                    <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'gims'}
                            onClick={selectFilter} active={selected.gims}>

                        {t('school.theory.groupsList.gims')}
                    </Button>
                </FormGroup>
            </Col>}
            <Col xs={'auto'}>
                <FormGroup>
                    {t('school.theory.groupsList.groups')}
                    <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'not_started'}
                            onClick={selectFilter} active={selected.not_started}>
                        {t('school.theory.groupsList.recruiting')}
                    </Button>
                    <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'started'}
                            onClick={selectFilter} active={selected.started}>
                        {t('school.theory.groupsList.learning')}
                    </Button>
                </FormGroup>
            </Col>
            <Col xs={'auto'}>
                <FormGroup>
                    {t('school.theory.groupsList.classes')}
                    <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'all'}
                            onClick={() => setRSelected(() => 'all')} active={rSelected === 'all'}>
                        {t('school.theory.groupsList.all')}
                    </Button>
                    <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'closest'}
                            onClick={() => setRSelected(() => 'closest')} active={rSelected === 'closest'}>
                        {t('school.theory.groupsList.closest')}
                    </Button>
                    <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'archived'}
                            onClick={() => setRSelected(() => 'archived')} active={rSelected === 'archived'}>
                        {t('school.theory.groupsList.archive')}
                    </Button>
                </FormGroup>
            </Col>
            {rSelected === 'closest' && <Col xl={3} lg={5} md={8} xs={12} className={'float-right'}>
                <FormGroup>
                    <RangedDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter} dateFormat={"DD.MM.YYYY"}/>
                </FormGroup>
            </Col>}
        </Row>
        <Row>
            <Col>
                <DataTable findAndCount={findAndCountEntries}
                           filters={{...filters, type}}
                           fields={[
                               field(t('school.theory.groupsList.group'), true, "name", res => <>
                                   <span className="title-underlined">{res.name}</span>
                                   <ElementWithPermissions disabled={!permissions.edit}>
                                       <button className={'btn btn-light btn-rounded btn-sm p-1 ml-1'}
                                               title={t('school.theory.groupsList.groupSettings')}
                                               onClick={() => history
                                                   .push(`/school/${type === "theory" ? "theory" : "lectures"}/group/edit/${res.url}`)}> <BsGear/>
                                       </button>
                                   </ElementWithPermissions>
                                   <ElementWithPermissions disabled={!permissions.edit}>
                                       <button className={'btn btn-light btn-rounded btn-sm p-1 ml-1'}
                                               title={t('school.theory.groupsList.copyGroup')}
                                               onClick={() => {
                                                   triggerModal(res);
                                               }}> <FaFile/>
                                       </button>
                                   </ElementWithPermissions>

                               </>),
                               field(t('school.theory.groupsList.place'), true, "place"),
                               field(t('school.theory.groupsList.lessonsPerWeek'), true, "lessons_per_week", res => {
                                   if (res.has_custom_schedule || res.type === 'lecture')
                                       return t('school.theory.groupsList.customSchedule')
                                   const weekdays = Array.isArray(res.weekdays) ? res.weekdays : [res.weekdays];
                                   const formattedDays = weekdays
                                       .sort((a, b) => a - b)
                                       .map((weekday, i) => <span key={i}>{daysAbbr[~~weekday]}</span>);

                                   return res.is_intensive
                                       ? <>{t('school.theory.groupsList.intensive')} {formattedDays.reduce((prev, curr) => [prev, ', ', curr])}</>
                                       : <>
                                           {weekdays.length} {t('school.theory.groupsList.lec')} {formattedDays.reduce((prev, curr) => [prev, ', ', curr])}
                                       </>;
                               }),
                               field(t('school.theory.groupsList.nextLesson'), true, "next_lesson_id.start_date",
                                   res => res.next_lesson_id ? <>
                                       {shortDate(res.next_lesson_id?.start_date)} {time(dayjs(res.next_lesson_id?.start_time, 'HH:mm:ss'))}
                                       , {daysAbbr[dayjs(res.next_lesson_id?.start_date).isoWeekday()]}
                                       , №{res.is_intensive
                                       ? res.next_lesson_id?.lesson_number + '-' + ((res.has_custom_schedule && '...') || (res.next_lesson_id?.lesson_number + 2))
                                       : res.next_lesson_id?.lesson_number}
                                   </> : ''),
                               field(t('school.theory.groupsList.teacher'), true, "teacher.last_name",
                                   res => `${res.teacher_id?.first_name?.[0] || t('school.theory.groupsList.noPicked')}. ${res.teacher_id?.last_name || ''}`
                               ),
                               field(t('school.theory.groupsList.bookedToTotal'), true, "booked_to_total",
                                 res => {
                                   const actualReserved = res.reserved_sits - res.booked_sits;
                                   return <>{res.booked_sits || 0} {Boolean(actualReserved) ? `(${actualReserved})` : ''} / {res.total_sits} <LoadBadge value={res.booked_to_total || 0}/></>
                                 }
                               ),
                           ]}
                           sortField="start_date"
                           sortDir="desc"
                           name="groups-list"
                           onClick={(group) => history
                               .push(`/school/${type === "theory" ? "theory" : "lectures"}/group/view/${group.url}`)}
                           onMiddleClick={onMiddleClick}
                           reload={reload}
                           onDelete={check(GroupRemoveKeyHandler[typeForRule]) && deleteGroup()}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <ElementWithPermissions disabled={!permissions.edit}>
                    <Button color={'primary'} outline className={'btn-rounded'} onClick={ () => triggerModal()}>
                        {t('school.theory.groupsList.newGroup')}
                    </Button>
                </ElementWithPermissions>
            </Col>
        </Row>
    </TableCard>
})

export default GroupsList;