import { Controller } from "react-hook-form";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import { FormGroup } from "reactstrap";
import React from "react";
import {t} from "i18next";
import { handleInputChange } from "../../../../lib/utils/handleInputChange";

const LessonsPerDayInput = ({ name, label, setEntity, control, errors, isRequired }) => {
    const handleLessonsChange = (e) => {
        const { name, value } = handleInputChange(e);
        const limitedValue = Math.max(1, Math.min(5, Number(value)));
        setEntity(entity => ({ ...entity, [name]: value }));
        return value;
    };

    return (
        <FormGroup>
            <Controller
                control={control}
                name={name}
                rules={{
                    required: isRequired && t('inputs.validation.required'),
                    min: {value: 1, message: t('inputs.validation.number', { minNumber: 1, maxNumber: 5 })},
                    max: {value: 5, message: t('inputs.validation.number', { minNumber: 1, maxNumber: 5 })},
                    validate: value => Number.isInteger(Number(value)) || t('inputs.validation.integer')
                }}
                render={({ value, onBlur, name, ref, onChange }, { invalid }) => (
                    <FieldWithValidation
                        invalid={invalid}
                        errors={errors}
                        label={label}
                        name={name}
                        isRequired={isRequired}
                        type="number"
                        placeholder="1"
                        onBlur={onBlur}
                        value={value}
                        min={1}
                        max={5}
                        onChange={e => onChange(handleLessonsChange(e))}
                    />
                )}
            />
        </FormGroup>
    );
};

export default LessonsPerDayInput;